import { sanitizeUrl } from "@braintree/sanitize-url";
import _ from "lodash";

function cleanUrl(rawUrl) {
  const httpsHead = _.take(rawUrl, 8).join("");
  const httpHead = _.take(rawUrl, 7).join("");

  if (httpsHead !== "https://" && httpHead !== "http://") {
    return sanitizeUrl(`https://${rawUrl}`);
  } else {
    return sanitizeUrl(rawUrl);
  }
}

export { cleanUrl };
